/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
class Write extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      comment: '',
      privacy: false,
      email:''
    };
  }
  handleSubmit = event => {
    if(this.state.privacy===true){
      console.log(this.state);
    document.getElementById("onSubmit").classList.remove("invisible")
    const formData = new FormData();

    formData.append('post_name', this.props.slug);
formData.append('username', this.state.username);
formData.append('comment', this.state.comment);
formData.append('privacy', this.state.privacy);
formData.append('email', this.state.email);



    fetch('https://h145011.web202.dogado.net/send.php', {
      method: 'POST', // or 'PUT'
      mode: 'no-cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json',
      },
      body: formData,
    })
    .then(response => {
      console.log('Success:', response);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
    window.location.reload(false); 
  }
    event.preventDefault()
  }

  handleNameInputChange = event => {
    const target = event.target;
    const value = target.value;

    this.setState({
      username: value
    });
  }

  handleCommentInputChange = event => {
    const target = event.target;
    const value = target.value;

    this.setState({
      comment: value
    });
  }

  handleEmailInputChange = event => {
    const target = event.target;
    const value = target.value;

    this.setState({
      email: value
    });
  }

  handlePrivacyInputChange = event => {
    const target = event.target;
    const value = target.checked;

    this.setState({
      privacy: value
    });
  }
  render() {
    return (
      <form
        className="w-full"
        onSubmit={this.handleSubmit}
      >
        <div className="md:flex md:items-center mb-0">
          <div className="md:w-1/4">
            <label
              className="block text-gray-900 font-bold md:text-left mb-1 md:mb-0 pr-4"
              htmlFor="username"
            >
              Name
            </label>
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-grey-700"
              id="username"
              type="text"
              onChange={this.handleNameInputChange}
            />
            <label
              className="block text-gray-900 invisible h-0 font-bold md:text-right"
              htmlFor="email"
            >
              Name
            </label>
            <input
              className="bg-gray-200 appearance-none invisible h-0 border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-grey-700"
              id="email"
              type="email"
              onChange={this.handleEmailInputChange}
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-3/4">
            <label
              className="block text-gray-900 font-bold md:text-left mb-1 md:mb-0 pr-4"
              htmlFor="comment"
            >
              Kommentar
            </label>
            <textarea
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-grey-700"
              id="comment"
              onChange={this.handleCommentInputChange}
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <label className="md:w-3/4 block text-gray-900 font-bold">
            <input className="mr-2 leading-tight" type="checkbox"
              onChange={this.handlePrivacyInputChange} />
            <span className="text-sm">
              zustimmung zur <a href="/privacy">Datenverarbeitung</a>
            </span>
          </label>
        </div>
        <div className="md:flex md:items-center">
          <div className="md:w-2/4">
            <button
              className="shadow bg-gray-700 hover:bg-gray-800 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Kommentieren
            </button>
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/4"></div>
          <div id="onSubmit" className="invisible md:w-3/4">
            <div
              className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
              role="alert"
            >
              <div className="flex">
                <div className="py-1">
                  <svg
                    className="fill-current h-6 w-6 text-teal-500 mr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </div>
                <div>
                  <p className="font-bold">Dein Kommentar wurde abgesendet</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default Write
