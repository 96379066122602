/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Comment from "./comments/comment"
import Write from "./comments/write"

class CommentSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      comments:[]
    }
  }
  componentDidMount() {
    const formData = new FormData()
    var url = new URL('https://h145011.web202.dogado.net/get.php')

    var params = {post_name:this.props.slug} 
    console.log(params)
    url.search = new URLSearchParams(params).toString();
    
    formData.append("post_name", "test-test")
    fetch(url)
    .then(function(response) {
      return response.json();
    })
      .then(response => {
        this.setState({comments:response})
      })
      .catch(error => {
        console.error("Error:", error)
      })
  }

  render() {
    return (
      <>
        <h2 className={"text-2xl mb-3"}>Kommentare</h2>
        <div className="flex flex-col">
          {this.state.comments.map(comment => (

            <Comment
            name={comment.username}
            time={comment.created}
            comment={comment.comment}
            key={comment.time}
            />
          ))}
          <Write slug={this.props.slug}></Write>
        </div>
      </>
    )
  }
}

export default CommentSection
