/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
class Comment extends React.Component{

  render() {
  const { name,time,comment } = this.props
  return (
    <div>
        <h3>{name}</h3>
        <p><small>{time}</small></p>
        <p>{comment}</p>
        <hr className="h-px mb-2" />
    </div>
  )}
}

export default Comment
