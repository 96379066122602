import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CommentSection from "../components/commentSection"

import Img from "gatsby-image"
class BlogPostTemplate extends React.Component {
  


  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    
    const minutes = Math.floor((post.fields.readingTime.time/60)/1000)
    const seconds = Math.floor((post.fields.readingTime.time/1000)%60)
    var minutesText = ((minutes>1)?minutes + " Minuten ":(minutes===1)?"1 Minute":"")
    var secondsText = ((seconds>1)?seconds + " Sekunden ":(seconds===1)?"1 Sekunde":"")

    console.log(post);
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article className="max-w-screen-md mx-auto">
          <header>
            <div class="post-img-wrapper">
              <Img fluid={post.frontmatter.featuredImage.childImageSharp.fluid} className={"mx-auto post-img"} />
            </div>
            <h1 className="text-3xl font-black mt-8 mb-0 serif  w-2/3">
              {post.frontmatter.title}
            </h1>

            <p className="text-sm leading-loose mb-8 inline float-right serif">
              {minutesText} {secondsText}{((minutes + seconds)===0)?"nichts":""}  zu lesen
            </p>
            <p className="text-sm leading-loose inline serif mb-8">
              {post.frontmatter.date}
            </p>
          </header>
          <section
            className="markdown"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <hr className="h-px mb-3" />
          {/* <CommentSection slug={post.fields.slug}/> */}
          <hr className="h-px mb-8" />
          <footer>
          </footer>
        </article>
        <nav>
          <ul
            className="flex flex-wrap justify-between mb-8"
            // style={{
            //   display: `flex`,
            //   flexWrap: `wrap`,
            //   justifyContent: `space-between`,
            //   listStyle: `none`,
            //   padding: 0,
            // }}
          >
            <li>
              {previous && (
                <Link
                  className="text-blue-600"
                  to={previous.fields.slug}
                  rel="prev"
                >
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link
                  className="text-blue-600"
                  to={next.fields.slug}
                  rel="next"
                >
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 300)
      html
      frontmatter {
        title
        tags
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
        readingTime {
          time
        }
      }
    }
  }
`
